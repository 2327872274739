<template>
    <div class="bgblue uMange">
        <el-card class="box-card" style="margin-right: 7px;">
            <el-row>
                <el-form label-width="90px">
                    <el-col :span="6">
                        <el-form-item label="代理商名称:">
                            <el-input v-model="queryInfo.condition.agentName" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="代理商编号:">
                            <el-input v-model="queryInfo.condition.agentCode" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label-width="75px" label="负责人:">
                            <el-input v-model="queryInfo.condition.agentHead" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="代理商区域:">
                            <el-select v-model="queryInfo.condition.status" placeholder="请选择" style="width:100%"
                                clearable>
                                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="联系方式:">
                            <el-input v-model="queryInfo.condition.agentArea" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="代理商等级:">
                            <el-input v-model="queryInfo.condition.agentGrade" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="padding-left: 50px;">
                        <el-button type="primary" icon="el-icon-search" @click="toSearch">查询</el-button>
                        <el-button icon="el-icon-refresh" @click="toReset">重置</el-button>
                    </el-col>
                </el-form>
            </el-row>
        </el-card>
        <el-card class="box-card" style="margin: 7px 7px 7px 0;">
            <!-- <el-button type="primary" style="margin: 0 0 10px 0;" icon="el-icon-plus"
                @click="toAdd()">新增代理商</el-button> -->
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%;margin-top: 0;"
                height="calc(100vh - 300px)" border>
                <el-table-column label="操作" fixed width="150" align="center">
                    <template v-slot={row}>
                        <el-button type="text" class="btn-blue" @click="toShowLog(row)">编辑</el-button>
                        <el-button type="text" class="btn-orange" @click="toPriceScheme(row)">价格方案</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="agentCode" label="代理商编号" min-width="200" align="center">
                </el-table-column>
                <el-table-column prop="agentName" label="代理商名称" min-width="120" align="center">
                </el-table-column>
                <el-table-column prop="agentHead" label="代理商负责人" min-width="100" align="center">
                </el-table-column>
                <el-table-column prop="agentContact" label="联系方式" min-width="130" align="center">
                </el-table-column>
                <el-table-column prop="agentIdentificationNumber" label="身份证号" min-width="200px" align="center">
                </el-table-column>
                <el-table-column prop="agentArea" label="代理商区域" align="center" min-width="100px">
                </el-table-column>
                <el-table-column prop="agentGrade" label="代理商等级" width="90" min-width="200px" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="200px" align="center">
                </el-table-column>
                <el-table-column prop="createDate" label="创建时间" min-width="150" align="center">
                </el-table-column>
            </el-table>
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.currPage" :page-sizes="[10, 20, 50, 100]" :page-size="10"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],

            // 分页查询
            queryInfo: {
                pageSize: 20,
                pageCount: 1,
                currPage: 1,
                condition: {
                    businessType: "",
                    accountItemName: ""
                }
            },
            total: 0,

            statusOptions: [
                {
                    value: "0",
                    label: "未支付"
                },
                {
                    value: "1",
                    label: "已支付"
                },
                {
                    value: "2",
                    label: "已退款"
                }
            ],
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            // this.queryInfo.condition.agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode;
            this.$http.post("/houseAgent/list", this.queryInfo).then(res => {
                if (res.data.code == 200) {
                    this.total = res.data.data.count;
                    this.tableData = res.data.data.data;
                }
            });
        },
        toAdd() {
            this.$router.push({ path: '/agentManagement/agentAdd' });
        },
        // 编辑
        toShowLog(row) {
          console.log(row,138);
          this.$router.push({ path: `/agentManagement/agentEdit?id=${row.id}` });
        },
        // 价格方案
        toPriceScheme(row) {
            this.$router.push({ path: `/agentManagement/agentScheme?agentCode=${row.agentCode}&agentName=${row.agentName}` });
        },
        toSearch() {
            this.getList();
        },
        // 重置
        toReset() {
            this.createDate = [];
            this.initiateDate = [];
            this.agentInsureDate = [];
            this.paymentApplyDate = [];
            this.paymentFinishDate = [];

            this.queryInfo.condition = {};
            this.getList();
        },
        // 修改页数大小
        handleSizeChange(val) {
            this.queryInfo.pageSize = val;
            this.getList();
        },

        // 获取当前页面
        handleCurrentChange(val) {
            this.queryInfo.currPage = val;
            this.getList();
        }
    }
};
</script>

<style lang="less" scoped>
.uMange {
    padding: 7px;
    .logInfoBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .logInfoItem {
            display: flex;
            gap: 10px;
        }

        .logInfoItem>div:first-child {
            width: 120px;
            font-weight: bold;
        }
    }

    .logHistory {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0;
    }

    .imgBox {
        //width: 148px;
        height: 148px;
        position: relative;
        //margin-bottom: 5px;
    }

    .imgBox:hover .img-icon {
        display: flex;
    }

    .img-icon {
        width: 148px;
        height: 148px;
        border-radius: 6px;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: rgba(0, 0, 0, 0.4);

        i {
            font-size: 26px;
            color: #fff;
        }
    }

    .avatar {
        display: block;
        width: 148px;
        height: 148px;
        border-radius: 6px;
    }
}
</style>
